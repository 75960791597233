<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">
		<div class="fill-height pa-5 pa-sm-10" v-if="AppStore.started_app" id="comment-page">
			<v-container class="fill-height d-flex align-center flex-row">
				<v-row class="">
					<v-card elevation="0" class="mx-auto pa-1 pa-sm-10 mb-5" rounded="xl">

						<v-col cols="12" class="text-h2 pt-5 pb-7 text-center" id="comment-title"
						       v-html="AppStore.data.configuration[AppStore.locale].comment_title">
						</v-col>

						<v-col cols="12 text-center" v-if="outlinedTextArea">
							<div id="comment-help" v-html="AppStore.data.configuration[AppStore.locale].comment_help"
							     :style="'color:'+AppStore.data.general.secondary_color.hexa"></div>
						</v-col>

						<v-col cols="12" class="">
							<ValidationProvider name="comment" :rules="commentRules" v-slot="{ errors }">
								<v-textarea
									v-model="AppStore.rating.comment"
									:error-messages="errors"
									:placeholder="_.includes([0,1,2], AppStore.rating.rate) ? AppStore.data.configuration[AppStore.locale].comment_negative_placeholder : AppStore.data.configuration[AppStore.locale].comment_positive_placeholder"
									persistent-placeholder
									outlined
									@focus="outlinedTextArea = true; commentFieldFocus = true"
									ref="comment"
									id="comment-textarea"
									rows="9"
									@input="getCommentRule()"
									persistent-hint
									class="mb-3"
									name="comment">
								</v-textarea>
							</ValidationProvider>
						</v-col>
						<v-col cols="12" id="comment-questions" class=""
						       v-if="questions_to_display && questions_to_display.length">
							<div v-for="(question, questionIndex) in questions_to_display"
							     :key="questionIndex" class="comment-question">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'slider'"
									:rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
								<text-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'text'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></text-view>
								<textarea-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'textarea'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
								<select-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'select'"
									:rules="question.is_required ? 'required' : ''"
									:name="questions_to_display[questionIndex].name"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
								<checkbox-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'checkbox'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
								<radio-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'radio'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
								<switch-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'switch'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>

						<v-col cols="12" class="text-center">
							<v-btn
								v-if="AppStore.rating.comment.trim().length < AppStore.data.configuration.comment_chars_required_number"
								@click="passes(submit)" rounded
								outlined elevation="0"
								:large="AppStore.data.general.button_size === 'large'"
								:x-large="AppStore.data.general.button_size === 'x-large'"
								:x-small="AppStore.data.general.button_size === 'x-small'"
								:small="AppStore.data.general.button_size === 'small'"
								:color="AppStore.data.general.primary_color.hexa" class="revert-btn text-button"
								:style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								v-html="AppStore.data.configuration[AppStore.locale].comment_retry_btn_text">
							</v-btn>
							<v-btn
								v-if="AppStore.rating.comment.trim().length >= AppStore.data.configuration.comment_chars_required_number"
								@click="passes(submit)" rounded elevation="0" dark
								:large="AppStore.data.general.button_size === 'large'"
								:x-large="AppStore.data.general.button_size === 'x-large'"
								:x-small="AppStore.data.general.button_size === 'x-small'"
								:small="AppStore.data.general.button_size === 'small'"
								@keypress.enter="navigate"
								class="text-button"
								:color="AppStore.data.general.primary_color.hexa"
								:style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								v-html="AppStore.data.configuration[AppStore.locale].comment_validate_btn_text">
							</v-btn>
						</v-col>
					</v-card>
				</v-row>
			</v-container>
		</div>
	</ValidationObserver>
</template>

<script>
import VTextAreaWithValidation from "../../../components/communs/inputs/VTextAreaWithValidation.vue";
import AppStore from "../../../stores/AppStore";
import _ from 'lodash';
import VueInputAutowidth from 'vue-input-autowidth'
import Vue from "vue";
import Stepper from "../../../components/communs/Stepper.vue";
import {EventBus} from "../../../services/Request";
import Helpers from "../../../services/Helpers";
import TextareaView from "../../../components/communs/inputViewer3/TextareaView.vue";
import TextView from "../../../components/communs/inputViewer3/TextView.vue";
import SliderView from "../../../components/communs/inputViewer3/SliderView.vue";
import SwitchView from "../../../components/communs/inputViewer3/SwitchView.vue";
import DividerView from "../../../components/communs/inputViewer3/DividerView.vue";
import CheckboxView from "../../../components/communs/inputViewer3/CheckboxView.vue";
import RadioView from "../../../components/communs/inputViewer3/RadioView.vue";
import SelectView from "../../../components/communs/inputViewer3/SelectView.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";

Vue.use(VueInputAutowidth)
export default {
	name: 'comment-view',

	components: {
		ValidationObserver, ValidationProvider,
		SelectView, RadioView, CheckboxView, DividerView, SwitchView, SliderView, TextView, TextareaView,
		VTextAreaWithValidation,
		Stepper
	},

	props: {},

	data: () => ({
		AppStore,
		commentRules: '',
		outlinedTextArea: false,
		questions: [],
		questions_to_display: [],
		commentFieldFocus: false,
	}),

	created() {

	},

	async mounted() {
		this.questions = []
		this.questions_to_display = []
		await this.getBookmarkedQuestions()
		if (AppStore.questions_by_page[3] && AppStore.questions_by_page[3].length > AppStore.data.configuration.questions_to_display_3) {
			await this.getRandomQuestions();
		} else {
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[3], (q) => {
				return !q.is_bookmarked
			}))
			this.questions_to_display = this.questions_to_display.concat(this.questions)
		}
	},

	computed: {
		Helpers() {
			return Helpers
		},
		_() {
			return _;
		}
	},

	watch: {},

	methods: {
		getBookmarkedQuestions() {
			this.questions_to_display = Object.assign([], AppStore.bookmarkedQuestions[3])
		},

		getRandomQuestions() {
			const max = AppStore.data.configuration.questions_to_display_3 - this.questions_to_display.length
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[3], (q) => {
				return !q.is_bookmarked
			}))

			for (let i = 0; i < max; i++) {
				const randomQuestionIndex = Math.floor(Math.random() * (this.questions.length));
				this.questions_to_display.push(this.questions[randomQuestionIndex]);
				this.questions.splice(randomQuestionIndex, 1)
			}
		},
		submit() {
			this.commentFieldFocus = false
			if (AppStore.data.configuration.skip_questions_page && !AppStore.data.configuration.skip_timeline_page) {
				EventBus.$emit('storeRating', 'noReset')
				this.$router.push({name: 'mb-3-timeline'})
			} else if (AppStore.data.configuration.skip_questions_page && AppStore.data.configuration.skip_timeline_page) {
				EventBus.$emit('storeRating', 'noReset')
				this.$router.push({name: 'mb-3-home'})
			} else {
				this.$router.push({name: 'mb-3-questions'})
			}
		},

		getCommentRule() {
			if (AppStore.rating.comment.length > 0 && AppStore.data.configuration.comment_chars_required_number > 0) {
				this.commentRules = 'required|min:' + AppStore.data.configuration.comment_chars_required_number
			} else {
				this.commentRules = ''
			}
		}
	}
};
</script>
<style scoped lang="scss">
.openKeyboardBtn {
	margin: 0 auto;
	display: block;
	min-width: 64px;
	height: 44px;
	padding: 0 16px;
	background-color: rgb(252, 119, 83);
	border-color: rgb(252, 119, 83);
	font-size: 1.2rem;
	color: white !important;
	border-radius: 28px;
	caret-color: transparent;
	font-weight: 700;
	letter-spacing: 0.0892857143em;
	justify-content: center;
	outline: 0;
	position: relative;
	text-decoration: none;
	text-indent: 0.0892857143em;
	text-transform: uppercase;
	transition-duration: 0.28s;
	transition-property: box-shadow, transform, opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
}

.openKeyboardBtn::placeholder {
	color: white !important;
}
</style>
